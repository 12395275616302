import { IPersonalData } from '@interfaces/IPersonalData';

export const personalDataInitialState: IPersonalData = {
    idCard: {
        surename: '',
        nameOfBirth: '',
        givenNames: '',
        pseudonym: '',
        dateOfBirth: '',
        colorOfEyes: '',
        nationality: '',
        street: '',
        streetNumber: '',
        postcode: '',
        validFrom: '',
        cardAccessNumber: '',
        authority: '',
        validTo: '',
        documentNumber: '',
    },
    passport: {
        surename: '',
        givenNames: '',
        pseudonym: '',
        dateOfBirth: '',
        sex: '',
        nationality: '',
        placeOfBirth: '',
        residence: '',
        colorOfEyes: '',
        height: '',
        type: '',
        code: '',
        passportNumber: '',
        dateOfIssue: '',
        dateOfExpiry: '',
        authority: '',
        cardAccessNumber: '',
    },
    birthCertificate: {
        surename: '',
        givenNames: '',
        placeOfBirth: '',
        dateOfBirth: '',
        sex: '',
        motherFamilyName: '',
        motherBirthName: '',
        motherGivenNames: '',
        motherReligion: '',
        fatherFamilyName: '',
        fatherBirthName: '',
        fatherGivenNames: '',
        fatherReligion: '',
        office: '',
        number: '',
        location: '',
        date: '',
        person: '',
    },
    baptismCertificate: {
        birthName: '',
        givenNames: '',
        placeOfBirth: '',
        dateOfBirth: '',
        motherName: '',
        fatherName: '',
        godparent1FamilyName: '',
        godparent1GivenNames: '',
        godparent2FamilyName: '',
        godparent2GivenNames: '',
        godparent3FamilyName: '',
        godparent3GivenNames: '',
        godparent4FamilyName: '',
        godparent4GivenNames: '',
        office: '',
        church: '',
        churchCommunity: '',
        date: '',
        person: '',
    },
    marriageCertificate: {
        husbandFamilyName: '',
        husbandBirthName: '',
        husbandGivenNames: '',
        husbandDateOfBirth: '',
        husbandPlaceOfBirth: '',
        husbandFamilyNameAfterMarriage: '',
        husbandBirthNameAfterMarriage: '',
        husbandGivenNamesAfterMarriage: '',
        wifeFamilyName: '',
        wifeBirthName: '',
        wifeGivenNames: '',
        wifeDateOfBirth: '',
        wifePlaceOfBirth: '',
        wifeFamilyNameAfterMarriage: '',
        wifeBirthNameAfterMarriage: '',
        wifeGivenNamesAfterMarriage: '',
        office: '',
        number: '',
        location: '',
        date: '',
        person: '',
    },
    divorceCertificate: {
        husbandFamilyName: '',
        husbandBirthName: '',
        husbandGivenNames: '',
        husbandDateOfBirth: '',
        husbandPlaceOfBirth: '',
        husbandFamilyNameAfterMarriage: '',
        husbandBirthNameAfterMarriage: '',
        husbandGivenNamesAfterMarriage: '',
        wifeFamilyName: '',
        wifeBirthName: '',
        wifeGivenNames: '',
        wifeDateOfBirth: '',
        wifePlaceOfBirth: '',
        wifeFamilyNameAfterMarriage: '',
        wifeBirthNameAfterMarriage: '',
        wifeGivenNamesAfterMarriage: '',
        office: '',
        number: '',
        location: '',
        date: '',
        person: '',
    },
    residentPermit: {
        surename: '',
        givenNames: '',
        sex: '',
        nationality: '',
        dateOfBirth: '',
        dateOfExpiry: '',
        type: '',
        remarks: '',
        remarksSecond: '',
        documentNumber: '',
        cardAccessNumber: '',
    },
};
