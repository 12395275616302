import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { get as getData, post as postData, update as updateData, deleteData } from '../common/api';

export interface Contact {
    id?: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    email: string;
    phone: string;
    contactType: string;
    comment: string;
    address: {
        street: string;
        streetNumber: string;
        postCode: string;
        city: string;
        country: string;
    };
}

interface Avatar {
    contactId: string;
    avatarUpload?: {
        url: string;
        expiresAt: string;
    };
    avatar?: {
        url: string;
        expiresAt: string;
    };
    avatarImage: string;
}
interface ContactsState {
    contacts: Contact[];
    selectedContact: Contact | null;
    editContact: Contact | null;
    isLoading: boolean;
    error: string | null;
    contactAvatars: Avatar[];

    fetchContacts: (token: string) => Promise<void>;
    addContact: (contact: Omit<Contact, 'id'>, token: string) => Promise<void>;
    updateContact: (id: string, contact: Partial<Contact>, token: string) => Promise<void>;
    deleteContact: (id: string, token: string) => Promise<void>;
    setSelectedContact: (contact: Contact | null) => void;
    setEditContact: (contact: Contact | null) => void;
    sendInvitation: (id: string, messsage: string, token: string) => Promise<void>;
    getContactAvatarUploadUrl: (id: string, token: string) => Promise<void>;
    getContactAvatarUrl: (id: string, token: string) => Promise<void>;
    uploadContactAvatar: (url: string, image: File) => Promise<void>;
    getContactAvatar: (id: string, url: string) => Promise<void> | null;
    removeContactAvatarImageFromStore: (id: string) => void;
    addContactAvatarImageToStore: (id: string, image: string) => void;
}

const useContactsStore = create<ContactsState>()(
    devtools((set, get) => ({
        contacts: [],
        selectedContact: null,
        editContact: null,
        isLoading: false,
        error: null,
        contactAvatars: [],

        fetchContacts: async (token) => {
            set({ isLoading: true, error: null });
            try {
                const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts`;
                const data: Contact[] = await getData(url, token);
                set({ contacts: data, isLoading: false });
            } catch (error) {
                set({
                    error: error instanceof Error ? error.message : 'Failed to fetch contacts',
                    isLoading: false,
                });
            }
        },

        addContact: async (contactData, token) => {
            set({ isLoading: true, error: null });
            try {
                const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts`;
                const newContact: Contact = await postData(url, contactData, token);
                const newContactWithId = { ...contactData, ...newContact };
                set((state) => ({
                    contacts: [...state.contacts, newContactWithId],
                    isLoading: false,
                }));
            } catch (error) {
                set({
                    error: error instanceof Error ? error.message : 'Failed to add contact',
                    isLoading: false,
                });
            }
        },

        updateContact: async (id, updatedContact, token) => {
            set({ isLoading: true, error: null });
            try {
                set((state) => ({
                    contacts: state.contacts.map((contact) => (contact.id === id ? { ...contact, ...updatedContact } : contact)),
                    selectedContact: null,
                    isLoading: false,
                }));

                const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}`;
                await updateData(url, updatedContact, token);
            } catch (error) {
                set({
                    error: error instanceof Error ? error.message : 'Failed to update contact',
                    isLoading: false,
                });
            }
        },

        deleteContact: async (id, token) => {
            set({ isLoading: true, error: null });
            try {
                set((state) => ({
                    contacts: state.contacts.filter((contact) => contact.id !== id),
                    selectedContact: null,
                    isLoading: false,
                }));

                const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}`;
                await deleteData(url, token);
            } catch (error) {
                set({
                    error: error instanceof Error ? error.message : 'Failed to delete contact',
                    isLoading: false,
                });
            }
        },

        sendInvitation: async (id, message, token) => {
            set({ isLoading: true, error: null });
            const sendMessage = { message: message };
            try {
                const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}/send-invitation`;
                await postData(url, sendMessage, token);
            } catch (error) {
                set({
                    error: error instanceof Error ? error.message : 'Failed to send invitation',
                    isLoading: false,
                });
            }
        },

        setSelectedContact: (contact) => {
            set({ selectedContact: contact });
        },

        setEditContact: (contact) => {
            set({ editContact: contact });
        },

        getContactAvatarUploadUrl: async (id, token) => {
            try {
                const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}/avatar-upload-url`;
                const response: { url: string; expiresAt: string } = await getData(url, token);

                set((state: { contactAvatars: Avatar[] }) => ({
                    contactAvatars: [
                        ...state.contactAvatars.filter((avatar) => avatar.contactId !== id),
                        {
                            ...(state.contactAvatars.find((avatar) => avatar.contactId === id) ?? {}),
                            contactId: id,
                            avatarUpload: response,
                        } as Avatar,
                    ],
                }));
            } catch (error) {
                console.error('Error getting avatar upload url:', error);
            }
        },
        getContactAvatarUrl: async (id, token) => {
            try {
                const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}/avatar-url`;
                const response: { url: string; expiresAt: string } = await getData(url, token);

                set((state) => {
                    const existingAvatar = state.contactAvatars.find((avatar) => avatar.contactId === id);

                    return {
                        contactAvatars: [
                            ...state.contactAvatars.filter((avatar) => avatar.contactId !== id),
                            {
                                contactId: id,
                                ...existingAvatar,
                                avatar: response,
                            } as Avatar,
                        ],
                    };
                });
            } catch (error) {
                console.error('Error getting avatar url:', error);
            }
        },

        uploadContactAvatar: async (url, image) => {
            try {
                await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'image/*',
                    },
                    body: image,
                });
            } catch (error) {
                console.error('Error during upload:', error);
            }
        },

        removeContactAvatarImageFromStore: (id) => {
            set((state) => {
                const newState = {
                    contactAvatars: state.contactAvatars.map((avatar) =>
                        avatar.contactId === id
                            ? {
                                  ...avatar,
                                  avatarImage: '',
                                  timestamp: Date.now(),
                              }
                            : avatar,
                    ),
                };
                return newState;
            });
        },

        addContactAvatarImageToStore: (id, image) => {
            set((state) => {
                const contactExists = state.contactAvatars.some((avatar) => avatar.contactId === id);

                if (contactExists) {
                    return {
                        contactAvatars: state.contactAvatars.map((avatar) =>
                            avatar.contactId === id
                                ? {
                                      ...avatar,
                                      avatarImage: image,
                                      timestamp: Date.now(),
                                  }
                                : avatar,
                        ),
                    };
                }

                return {
                    contactAvatars: [
                        ...state.contactAvatars,
                        {
                            contactId: id,
                            avatarImage: image,
                            timestamp: Date.now(),
                        },
                    ],
                };
            });
        },

        getContactAvatar: async (id, url) => {
            try {
                const existingAvatar = get().contactAvatars.find((avatar) => avatar.contactId === id);
                if (existingAvatar?.avatarImage) {
                    URL.revokeObjectURL(existingAvatar.avatarImage);
                }

                const response = await fetch(url, {
                    cache: 'reload',
                    headers: {
                        'Cache-Control': 'no-cache, no-store, must-revalidate',
                        Pragma: 'no-cache',
                        Expires: '0',
                    },
                });

                if (response.status === 404) {
                    set((state) => {
                        const newState = {
                            contactAvatars: state.contactAvatars.map((avatar) =>
                                avatar.contactId === id
                                    ? {
                                          ...avatar,
                                          avatarImage: '',
                                          timestamp: Date.now(),
                                      }
                                    : avatar,
                            ),
                        };
                        return newState;
                    });
                    return;
                }

                const newBlob = await response.blob();
                const avatarImage = URL.createObjectURL(newBlob);

                set((state) => {
                    const newState = {
                        contactAvatars: state.contactAvatars.map((avatar) =>
                            avatar.contactId === id
                                ? {
                                      ...avatar,
                                      avatarImage,
                                      timestamp: Date.now(),
                                  }
                                : avatar,
                        ),
                    };
                    return newState;
                });
            } catch (error) {
                console.error('Error getting avatar:', error);
            }
        },
    })),
);

export default useContactsStore;
