// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>

import { Navigate } from 'react-router-dom';
import { useUser } from '@contexts/UserProvider';
import React from 'react';

const PublicRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const user = useUser();
    if (user?.currentUser === undefined) {
        return null;
    } else if (user?.currentUser) {
        // return <Navigate to="/dashboard" />;
        return <Navigate to="/wizards" />;
    } else {
        return <>{children}</>;
    }
};

export default PublicRoute;
