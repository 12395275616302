import React, { useEffect } from 'react';
import { useUser } from '@contexts/UserProvider';
import useContactsStore from '@stores/useContactsStore';
import { useUserSettingStore } from '@stores/useUserSettingsStore';
import { useWizardStore } from '@stores/useWizzardStore';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from './MainLayout';

const Wizard = React.lazy(() => import('../../pages/Wizard/Wizard'));
const Documents = React.lazy(() => import('../../pages/Documents/Documents'));
const Contacts = React.lazy(() => import('../../pages/Contacts/Contacts'));

const AuthenticatedLayout = () => {
    const user = useUser();
    const { fetchUserDocuments } = useWizardStore();
    const { fetchUserSettings, fetchUserSettingsDetails } = useUserSettingStore();
    const { fetchContacts } = useContactsStore();

    useEffect(() => {
        const token = user.currentUser?.accessToken;
        if (token) {
            void fetchUserDocuments(token);
            void fetchUserSettings(token);
            void fetchUserSettingsDetails(token);
            void fetchContacts(token);
        }
    }, [user.currentUser?.accessToken]);

    return (
        <MainLayout>
            <Routes>
                <Route path="/wizards" element={<Wizard />} />
                <Route path="/documents" element={<Documents />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </MainLayout>
    );
};

export default AuthenticatedLayout;
