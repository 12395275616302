import { IDirectives } from '@interfaces/IDirectives';

export const directivesDataInitialState: IDirectives = {
    durablePowerOfAttorneyForHealthCare: {
        myselfFamilyName: '',
        myselfGivenNames: '',
        myselfDateOfBirth: '',
        myselfPlaceOfBirth: '',
        myselfStreetName: '',
        myselfStreetNumber: '',
        myselfPostcode: '',
        myselfCity: '',
        myselfTelephone: '',
        myselfMobile: '',
        representativeFamilyName: '',
        representativeGivenNames: '',
        representativeDateOfBirth: '',
        representativePlaceOfBirth: '',
        representativeStreetName: '',
        representativeStreetNumber: '',
        representativePostcode: '',
        representativeCity: '',
        representativeTelephone: '',
        representativeMobile: '',
        representativeInformedContacts: '',
        representativeSignatureDate: '',
        representativeSignaturePlace: '',
        healthAndCare: [],
        placeOfResidence: [],
        financials: [],
        others: [],
    },
    livingWill: {
        familyName: '',
        givenNames: '',
        dateOfBirth: '',
        placeOfBirth: '',
        streetName: '',
        streetNumber: '',
        postcode: '',
        city: '',
        telephone: '',
        mobile: '',
        emptySpace: '',
        initialSignatureDate: '',
        initialSignaturePlace: '',
        lastSignatureDate: '',
        lastSignaturePlace: '',
        whenDoesTheLivingWillApply: [],
        medicalMeasuresDesiredOrNot: [],
    },
    careDirective: {
        familyName: '',
        givenNames: '',
        dateOfBirth: '',
        placeOfBirth: '',
        streetName: '',
        streetNumber: '',
        postcode: '',
        city: '',
        telephone: '',
        mobile: '',
        caregiver_1FamilyName: '',
        caregiver_1GivenNames: '',
        caregiver_1DateOfBirth: '',
        caregiver_1PlaceOfBirth: '',
        caregiver_1StreetName: '',
        caregiver_1StreetNumber: '',
        caregiver_1Postcode: '',
        caregiver_1City: '',
        caregiver_1Telephone: '',
        caregiver_1Mobile: '',
        caregiver_2FamilyName: '',
        caregiver_2GivenNames: '',
        caregiver_2DateOfBirth: '',
        caregiver_2PlaceOfBirth: '',
        caregiver_2StreetName: '',
        caregiver_2StreetNumber: '',
        caregiver_2Postcode: '',
        caregiver_2City: '',
        caregiver_2Telephone: '',
        caregiver_2Mobile: '',
        nowayCaregiverFamilyName: '',
        nowayCaregiverGivenNames: '',
        nowayCaregiverDateOfBirth: '',
        nowayCaregiverPlaceOfBirth: '',
        nowayCaregiverStreetName: '',
        nowayCaregiverStreetNumber: '',
        nowayCaregiverPostcode: '',
        nowayCaregiverCity: '',
        nowayCaregiverTelephone: '',
        nowayCaregiverMobile: '',
        myWishes: [],
        signatureDate: '',
        signaturePlace: '',
    },
    bankPowerOfAttorney: {
        familyName: '',
        givenNames: '',
        dateOfBirth: '',
        placeOfBirth: '',
        streetName: '',
        streetNumber: '',
        postcode: '',
        city: '',
        telephone: '',
        mobile: '',
        bankName: '',
        bankStreetName: '',
        bankStreetNumber: '',
        bankPostcode: '',
        bankCity: '',
        bankAccount: '',
        bankDepot: '',
        representativeFamilyName: '',
        representativeGivenNames: '',
        representativeDateOfBirth: '',
        representativePlaceOfBirth: '',
        representativeStreetName: '',
        representativeStreetNumber: '',
        representativePostcode: '',
        representativeCity: '',
        representativeTelephone: '',
        representativeMobile: '',
        typeOfDocument: '',
        validFrom: '',
        signatureDate: '',
        signaturePlace: '',
    },
    postalPowerOfAttorney: {
        familyName: '',
        givenNames: '',
        dateOfBirth: '',
        placeOfBirth: '',
        streetName: '',
        streetNumber: '',
        postcode: '',
        city: '',
        telephone: '',
        mobile: '',
        representativeFamilyName: '',
        representativeGivenNames: '',
        representativeDateOfBirth: '',
        representativePlaceOfBirth: '',
        representativeStreetName: '',
        representativeStreetNumber: '',
        representativePostcode: '',
        representativeCity: '',
        representativeTelephone: '',
        representativeMobile: '',
        haveAccessTo: [],
        validFrom: '',
        validTo: '',
        signatureDate: '',
        signaturePlace: '',
    },
};
