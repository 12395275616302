import { IVehicles } from '@interfaces/IVehicles';

export const vehicleDataInitialState: IVehicles = {
    driversLicense: {
        firstName: '',
        familyName: '',
        dateOfBirth: '',
        placeOfBirth: '',
        authority: '',
        driversLicenseNumber: '',
        validFrom: '',
        validTo: '',
        drivingLicenseClasses: [],
    },
    vehicleRegistrationDocument: {
        ownersName: '',
        emptySpace: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
        vehicleIdentificationNumber: '',
        licensePlateNumber: '',
        brand: '',
        model: '',
        enginePower: '',
        displacement: '',
        grossVehicleWeight: '',
        vehicleClass: '',
        vehicleColor: '',
        numberOfSeats: '',
        emissionStandard: '',
        dateOfFirstRegistration: '',
    },
    certificateOfOwnership: {
        vehicleIdentificationNumber: '',
        licensePlateNumber: '',
        brand: '',
        model: '',
        engineSize: '',
        power: '',
        numberOfSeats: '',
        grossVehicleWeight: '',
        emissionStandard: '',
        previousOwners: '',
        firstRegistrationDate: '',
        color: '',
        ownersName: '',
        emptySpace: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
    },
    carInsurance: {
        nameOfTheInsuranceCompany: '',
        insurancePolicyNumber: '',
        nameOfThePolicyholder: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
        country: '',
        brand: '',
        model: '',
        vehicleIdentificationNumber: '',
        licensePlateNumber: '',
        insuranceCoverage: [],
        maximumAmountForPersonalInjury: '',
        maximumAmountForPropertyDamage: '',
        deductibleAmount: '',
        premiumAmount: '',
        paymentSchedule: '',
        startDateOfThePolicy: '',
        endDateOfThePolicy: '',
        aditionalBenefits: [],
        claimDiscount: '',
    },
};
