// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>

import { Container } from 'react-bootstrap';

const TermsAndConditions = () => {
    return (
        <div>
            <Container className="content-container">
                <div className="d-flex flex-column">
                    <h3 className="justify-left">Terms And Conditions</h3>
                    <strong>Allgemeine Geschäftsbedingungen (AGB)</strong>
                    <br />
                    <strong>1. Geltungsbereich</strong>
                    <br />
                    <p>
                        Diese AGB gelten für sämtliche Rechtsverhältnisse zwischen dem Hauptnutzer, den Vertrauenspersonen (zusammen „Nutzer“) und finBalance,
                        die durch Vertragsschluss sowie durch die Nutzung der Plattform entstehen. Sie legen die Rechte und Pflichten bei der Nutzung der
                        Plattform fest, insbesondere im Hinblick auf das Hochladen, Speichern und Verwalten von Daten und Dokumenten.
                    </p>
                    <br />
                    <strong>2. Leistungsbeschreibung</strong>
                    <br />
                    <p>
                        a. finBalance stellt eine digitale Plattform zur Verfügung, die der sicheren Speicherung und Verwaltung von Daten und Dokumenten dient.
                    </p>
                    <p>
                        b. Ein Hauptnutzer ist eine Person, die sich auf der Plattform registriert und dort eine digitale Vorsorgeakte erstellt und verwaltet
                        („Vorsorgefunktion“). Diese Vorsorgeakte ist standardmäßig nur für den Hauptnutzer einsehbar und zugänglich, jedoch besteht die
                        Möglichkeit, ausgewählte Vertrauenspersonen für den Zugriff freizuschalten.
                    </p>
                    <p>
                        c. Vertrauenspersonen sind Personen, die auf der Plattform registriert sind und über dieses Konto Zugriff auf eine digitale Vorsorgeakte
                        eines Hauptnutzers gewährt bekommen („Vertrauensfunktion“).
                    </p>
                    <p>
                        d. Soweit ein Nutzer sowohl Hauptnutzer als auch Vertrauensperson eines anderen Hauptnutzers ist, kann er in seinem Konto zwischen
                        diesen Rollen und damit der Vorsorge- und Vertrauensfunktion hin- und herwechseln. Es ist nicht notwendig ein separates zweites Konto zu
                        erstellen.
                    </p>
                    <p>
                        e. finBalance behält sich das Recht vor, den Funktionsumfang der Plattform zu erweitern, einzuschränken oder anzupassen. Die Nutzer
                        werden über solche Neuerungen rechtzeitig informiert und sind verpflichtet, sich mit den entsprechenden Informationen vertraut zu
                        machen. Es wird darauf hingewiesen, dass neue Funktionen Einfluss auf bestehende Zugriffsrechte nehmen können.
                    </p>
                    <br />
                    <strong>3. Registrierung und Benutzerkonto</strong>
                    <br />
                    <p>a. Zur Nutzung der Plattform ist die Registrierung erforderlich.</p>
                    <p>
                        b. Die Nutzer verpflichten sich, lediglich korrekte und vollständige Angaben zu machen und diese bei Änderungen unverzüglich zu
                        aktualisieren.
                    </p>
                    <p>
                        c. Die Nutzer sind verpflichtet, ihre Zugangsdaten sicher aufzubewahren und vor unbefugtem Zugriff zu schützen. Eine Übertragung des
                        eigenen Kontos auf Dritte ist nicht gestattet.
                    </p>
                    <br />
                    <strong>4. Testzeitraum und kostenpflichtige Nutzung</strong>
                    <br />
                    <p>a. Die Nutzung der Vorsorgefunktion ist grundsätzlich kostenpflichtig.</p>
                    <p>
                        b. Die Nutzung der Vertrauensfunktion ist kostenfrei, soweit die Vertrauensperson nicht gleichzeitig Hauptnutzer im Sinne der Nummer
                        2.b. ist.
                    </p>
                    <p>
                        c. finBalance gewährt Nutzern einen kostenlosen Testzeitraum für die Vorsorgefunktion von acht Wochen. Nach Ablauf dieser Zeit wird die
                        Vorsorgefunktion in einen passiven Modus versetzt. In diesem Modus kann der Nutzer lediglich dies gespeicherten Dokumente einsehen
                        und/oder herunterladen. Der Nutzer hat die Wahl, die Vorsorgefunktion kostenpflichtig weiterzubetreiben oder diese zu beenden. Wenn der
                        Nutzer innerhalb von vier Wochen nicht auf ein kostenpflichtiges Modell wechselt, werden alle gespeicherten Daten und Dokumente
                        automatisch aus dem persönlichen Konto entfernt, ebenso wie die erteilten Zugriffsrechte und die für seine Vorsorgeakte angelegten
                        Vertrauenspersonen.
                    </p>
                    <p>
                        d. Zur Klarstellung: Wenn innerhalb eines Kontos sowohl eine Vorsorgefunktion als auch eine Vertrauensfunktion eingerichtet
                        ist, besteht die Vertrauensfunktion auch nach Beendigung der Vorsorgefunktion weiter.
                    </p>
                    <br />
                    <strong>5. Nutzungs- und Zugriffsrechte der Dokumente</strong>
                    <br />
                    <p>a. Der Nutzer behält sämtliche Rechte an den von ihm hochgeladenen Dokumenten.</p>
                    <p>
                        b. Um die digitale Vorsorgeakte für Vertrauenspersonen freizugeben, legt der Hauptnutzer Vertrauenspersonen in seinem persönlichen Konto
                        an und weist diesen Vertrauenspersonen individuelle Zugriffsrechte zu (Details dazu unter c.). Die Vertrauenspersonen erhalten sodann
                        Einladungs-E-Mails, über die sie sich ein eigenes Konto erstellen können. Für den Fall, dass die Vertrauensperson bereits ein
                        Hauptnutzer im Sinne der Nummer 2.b. ist, wird lediglich abgefragt, ob die Vertrauensfunktion akzeptiert wird. Der Nutzung der
                        Vertrauensfunktion ist erst nach Erstellung eines eigenen Kontos bzw. Bestätigung der Nutzung der Vertrauensperson möglich. Anschließend
                        ist der Zugriff gemäß den vom Hauptnutzer festgelegten Berechtigungen möglich.
                    </p>
                    <p>
                        c. Der Nutzer weist den Vertrauenspersonen jeweils einem Vertrauenskreis zu. Die einzelnen Vertrauenskreise sind gestaffelt und verfügen
                        über abgestufte Zugriffsrechte. Der innerste Kreis umfasst Personen mit höchstem Vertrauen und weitreichenden Zugriffsrechten, während
                        weiter außen liegende Kreise entsprechend eingeschränktere Berechtigungen erhalten. Die zugewiesenen Rechte erstrecken sich von
                        umfassendem Lese-, Änderungs- und Löschzugriff bis hin zu lediglich eingeschränktem Leserecht. Die erteilten Zugriffrechte bleiben
                        bestehen, solange die jeweilige Zuordnung besteht.
                    </p>
                    <p>d. Die Verantwortung für die Richtigkeit der erteilten Zugriffsrechte liegt beim Hauptnutzer.</p>
                    <p>e. Jeder Zugriff auf die Dokumente wird dokumentiert und kann vom Hauptnutzer eingesehen werden.</p>
                    <br />
                    <strong>6. Verantwortlichkeiten und Pflichten des Nutzers</strong>
                    <br />
                    <p>
                        Der Nutzer ist dafür verantwortlich, dass die von ihm hochgeladenen Daten keine Rechte Dritter verletzen und gesetzeskonform sind. Er
                        ist verpflichtet, die hochgeladenen Daten regelmäßig zu überprüfen und zu aktualisieren.
                    </p>
                    <br />
                    <strong>7. Haftung</strong>
                    <br />
                    <p>
                        a. finBalance haftet nur für Vorsatz und grobe Fahrlässigkeit. Eine Haftung für Datenverluste, technische Störungen oder Schäden, die
                        durch die Nutzung der Plattform entstehen, ist ausgeschlossen, soweit gesetzlich zulässig.
                    </p>
                    <p>
                        b. finBalance stellt lediglich die technische Infrastruktur zur Verfügung und übernimmt keine Haftung für die Inhalte und Richtigkeit
                        der hochgeladenen Daten.
                    </p>
                    <p>
                        c. Die Haftung von finBalance ist der Höhe nach auf vertragstypische Schäden begrenzt, die in ihrer Art und ihrem Umfang bei
                        Vertragsschluss vorhersehbar waren und den Wert der in den letzten zwölf Monaten vor dem Schadensereignis gezahlten Vergütungen nicht
                        überschreiten.
                    </p>
                    <br />
                    <strong>8. Kündigung</strong>
                    <br />
                    <p>
                        a. Die Vertragslaufzeit für die Vorsorgefunktion beträgt je nach Modell ein, zwei, fünf, zehn oder 25 Jahre. Nach Ablauf der jeweiligen
                        Laufzeit verlängert sich der Vertrag jedes Jahr automatisch um ein Jahr, sofern der Hauptnutzer nicht vor Ablauf der aktuellen Laufzeit
                        kündigt.
                    </p>
                    <p>b. Nutzer können ihr Konto jederzeit ohne Angabe von Gründen löschen, anteilige Rückzahlungen sind ausgeschlossen.</p>
                    <p>
                        c. finBalance behält sich das Recht vor, Konten bei Verstößen gegen diese AGB zu sperren oder zu löschen. Auch in diesem Fall sind
                        anteilige Rückzahlungen ausgeschlossen.
                    </p>
                    <br />
                    <strong>9. Sonstiges</strong>
                    <br />
                    <p>
                        a. finBalance behält sich das Recht vor, diese AGB jederzeit zu ändern. Durch die weitere Nutzung der Plattform nach der Änderung
                        erklärt der Nutzer sein Einverständnis zu den geänderten Bedingungen.
                    </p>
                    <p>
                        b. Sollte eine Bestimmung dieser AGB ganz oder teilweise unwirksam sein, bleibt die Wirksamkeit der übrigen Bestimmungen hiervon
                        unberührt. An die Stelle der unwirksamen Bestimmung tritt eine Regelung, die dem wirtschaftlichen Zweck der betreffenden Bestimmung am
                        nächsten kommt. Entsprechendes gilt für etwaige Regelungslücken.
                    </p>
                    <p>
                        c. Das anwendbare Recht ist deutsches Recht unter Ausschluss des Kollisionsrechts und des UN-Kaufrechts. Gerichtsstand für alle
                        Streitigkeiten aus der Nutzung der Plattform ist Berlin.
                    </p>
                </div>
            </Container>
        </div>
    );
};

export default TermsAndConditions;
