// // © 2024 finBalance - Ingo.Brenckmann@finbalance.de
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/index';
import './App.css';
import initI18n from './i18n';

import UserProvider from '@contexts/UserProvider';
import NotificationProvider from '@contexts/NotificationProvider';

import PublicRoute from '@components/publicPrivateRoute/PublicRoute';
import PrivateRoute from '@components/publicPrivateRoute/PrivateRoute';

import WelcomePage from './pages/WelcomePage/WelcomePage';
import Loading from '@components/loading/Loading';
import ErrorBoundary from '@components/errorBoundary';
import AuthenticatedLayout from '@components/layout/AuthenticatedLayout';

async function setupApp() {
    await initI18n();
}

function App() {
    useEffect(() => {
        void setupApp();
    }, []);

    return (
        <div className="App">
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <NotificationProvider>
                        <UserProvider>
                            <ErrorBoundary fallback={<div>Something went wrong. Please try again later.</div>}>
                                <Suspense fallback={<Loading open={false} />}>
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={
                                                <PublicRoute>
                                                    <WelcomePage />
                                                </PublicRoute>
                                            }
                                        />
                                        <Route
                                            path="*"
                                            element={
                                                <PrivateRoute>
                                                    <AuthenticatedLayout />
                                                </PrivateRoute>
                                            }
                                        />
                                    </Routes>
                                </Suspense>
                            </ErrorBoundary>
                        </UserProvider>
                    </NotificationProvider>
                </ThemeProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
