import { create } from 'zustand';
import { IPersonalData } from '@interfaces/IPersonalData';
import { IDirectives } from '@interfaces/IDirectives';
import { personalDataInitialState } from './personalDataInitialState';
import { get as getData, post as postData, update as updateData, deleteData } from '../common/api';
import { IDocument } from '@interfaces/IDocument';
import { IVehicles } from '@interfaces/IVehicles';
import templates from '../templates.json';
import { directivesDataInitialState } from './directivesDataInitialState';
import { vehicleDataInitialState } from './vehicleDataInitialState';
import { devtools } from 'zustand/middleware';
import { useUserSettingStore } from './useUserSettingsStore';

export interface WizardFormData {
    personalData: IPersonalData;
    directives: IDirectives;
    vehicles: IVehicles;
}

interface InitialQuestions {
    gender: string;
    relationship: string;
}
interface InfoConfirmed {
    directivesIntro: boolean;
    directivesAppInfo: boolean;
}

interface Salutation {
    termsAndConditionsAccepted: boolean;
    firstName: string;
    lastName: string;
    displayName: string;
}

interface UploadUrlResponse {
    url: string;
}

interface WizardStore {
    salutation: Salutation;
    initialQuestions: InitialQuestions;
    infoConfirmed: InfoConfirmed;
    formData: WizardFormData;
    directivesDataNotRelevant: string[];
    vehiclesDataNotRelevant: string[];
    userDocuments?: IDocument[];
    loading: boolean;
    error: null | string;
    activeWizard: keyof WizardFormData | '';
    fileExists: boolean;
    fileUrl: string | null;
    updateWizardData: <K extends keyof WizardFormData[keyof WizardFormData]>(
        data: WizardFormData[keyof WizardFormData][K],
        step: K,
        wizard?: keyof WizardFormData,
    ) => void;
    updateInfoConfirmed: (data: Partial<InfoConfirmed>) => void;
    updateSalutation: (data: Partial<Salutation>) => void;
    isStepDataFilled: (step: keyof WizardFormData[keyof WizardFormData]) => boolean;
    calculateDataProgress: (wizard: string) => { stepsProgress: { name: string; progress: number }[]; totalProgress: number };
    fetchDataForStep: <K extends keyof WizardFormData[keyof WizardFormData]>(
        step: K,
        id: string,
        token: string,
        wizard?: keyof WizardFormData,
    ) => Promise<void>;
    postDataForStep: <K extends keyof WizardFormData[keyof WizardFormData]>(step: K, token: string, wizard?: keyof WizardFormData) => Promise<void>;
    updateDataForStep: <K extends keyof WizardFormData[keyof WizardFormData]>(
        step: K,
        id: string,
        token: string,
        wizard?: keyof WizardFormData,
    ) => Promise<void>;
    fetchUserDocuments: (token: string) => Promise<void>;
    getFileUploadUrl: (id: string, token: string) => Promise<string | undefined>;
    deleteFile: (id: string, token: string, docType: string) => Promise<string | undefined>;
    deleteDataForStep: <K extends keyof WizardFormData[keyof WizardFormData]>(
        step: K,
        id: string,
        token: string,
        wizard?: keyof WizardFormData,
    ) => Promise<void>;
    setActiveWizard: (wizard: keyof WizardFormData | '') => void;
    uploadDocumentFile: (url: string, image: File, docType: string) => Promise<void>;
}

export const useWizardStore = create<WizardStore>()(
    devtools((set, get) => ({
        userDocuments: [],
        salutation: {
            firstName: '',
            lastName: '',
            displayName: '',
            termsAndConditionsAccepted: false,
        },
        initialQuestions: {
            gender: 'Choose',
            relationship: 'Choose',
        },
        infoConfirmed: {
            directivesIntro: false,
            directivesAppInfo: false,
        },
        formData: {
            personalData: personalDataInitialState,
            directives: directivesDataInitialState,
            vehicles: vehicleDataInitialState,
        },
        directivesDataNotRelevant: [],
        vehiclesDataNotRelevant: [],
        loading: false,
        error: null,
        activeWizard: '',
        fileExists: false,
        fileUrl: null,
        userTotalSteps: 0,
        updateWizardData: <K extends keyof WizardFormData[keyof WizardFormData]>(
            data: WizardFormData[keyof WizardFormData][K],
            step: K,
            wizard?: keyof WizardFormData,
        ) => {
            const activeWizard = wizard ?? get().activeWizard;
            if (!activeWizard) return;

            const updatedData = {
                ...(get().formData[activeWizard][step] as object),
                ...(data as object),
            };

            const filledKeys = Object.keys(updatedData).filter((key) => updatedData[key as keyof typeof updatedData] !== '');

            set((state) => ({
                formData: {
                    ...state.formData,
                    [activeWizard]: {
                        ...state.formData[activeWizard],
                        [step]: {
                            ...(state.formData[activeWizard][step] as object),
                            ...(data as object),
                        },
                    },
                },
                userDocuments: state.userDocuments?.map((doc) =>
                    doc.metadata.docType === step
                        ? {
                              ...doc,
                              payloadState: {
                                  ...doc.payloadState,
                                  filled: filledKeys,
                              },
                          }
                        : doc,
                ),
            }));
        },

        updateInfoConfirmed: (data) =>
            set((state) => ({
                infoConfirmed: {
                    ...state.infoConfirmed,
                    ...data,
                },
            })),

        updateSalutation: (data) =>
            set((state) => ({
                salutation: {
                    ...state.salutation,
                    ...data,
                },
            })),

        isStepDataFilled: (step: keyof WizardFormData[keyof WizardFormData]) => {
            if (step === 'initialQuestions') {
                const stepData = get().initialQuestions;
                return Object.values(stepData).every((value) => value !== 'Choose');
            } else {
                const activeWizard = get().activeWizard;
                if (!activeWizard) return false;
                const stepData = get().formData[activeWizard][step] as object;
                return Object.entries(stepData).every(([value]) => {
                    return value !== '';
                });
            }
        },

        calculateDataProgress: (wizard: string) => {
            const templateDocuments = templates.filter((item) => item.metadata.category === wizard);
            const userDocuments = get().userDocuments;
            const allUserDocumentsByWizard = templateDocuments.map((template) => {
                const userDocument = userDocuments?.find((doc) => doc.metadata.docType === template.metadata.id);
                return {
                    ...template,
                    filled: userDocument?.payloadState!.filled ? true : false,
                    wizard: template.metadata.category,
                    isFileUploaded: userDocument?.isFileUploaded,
                    totalFields: template.data.reduce((total, group) => total + group.fields.length, 0),
                    filledFields: userDocument?.payloadState!.filled?.length,
                };
            });
            const filledSteps = allUserDocumentsByWizard.filter((item) => item.filled === true);
            const stepsProgress = filledSteps.map((step) => {
                return {
                    name: step.metadata.id,
                    progress: Math.round((step.filledFields! / step.totalFields) * 100) / 2 + (step.isFileUploaded ? 50 : 0),
                };
            });

            const totalSteps = useUserSettingStore.getState().userSettingsDetails.userTotalSteps || allUserDocumentsByWizard.length;
            const totalProgress = Math.round(stepsProgress.reduce((total, step) => total + step.progress / totalSteps, 0));

            return { stepsProgress: stepsProgress, totalProgress: totalProgress };
        },

        fetchUserDocuments: async (token: string) => {
            set({ loading: true });
            const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents`;

            try {
                const data: IDocument[] = await getData(url, token);
                set((state) => ({
                    ...state,
                    userDocuments: data,
                    loading: false,
                }));
            } catch (error) {
                set({ error: (error as Error).message, loading: false });
            }
        },

        fetchDataForStep: async <K extends keyof WizardFormData[keyof WizardFormData]>(step: K, id: string, token: string, wizard?: keyof WizardFormData) => {
            const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents/${id}`;

            try {
                const data: IDocument = await getData(url, token);
                const activeWizard = wizard ?? get().activeWizard;

                if (!activeWizard) return;

                const fileExists = Boolean(data.file);
                const fileUrl = data.file?.largeThumbnailLink?.url ?? null;

                set((state) => ({
                    formData: {
                        ...state.formData,
                        [activeWizard]: {
                            ...state.formData[activeWizard],
                            [step]: {
                                ...(state.formData[activeWizard][step] as object),
                                ...data.payload,
                            },
                        },
                    },
                    fileExists,
                    fileUrl,
                }));
            } catch (error) {
                set({ error: (error as Error).message });
            } finally {
                set({ loading: false });
            }
        },

        postDataForStep: async <K extends keyof WizardFormData[keyof WizardFormData]>(step: K, token: string, wizard?: keyof WizardFormData) => {
            const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents`;
            const activeWizard = wizard ?? get().activeWizard;

            if (!activeWizard) return;

            try {
                set({ loading: true });
                const state = get();
                const payload = state.formData[activeWizard][step];
                const filledKeys = (Object.keys(payload) as (keyof WizardFormData[keyof WizardFormData][typeof step])[]).filter((key) => payload[key] !== '');

                const data = {
                    metadata: {
                        docType: step,
                        docStatus: 'valid',
                        docReviewDate: '2025-06-20',
                    },
                    payload: payload,
                    payloadState: {
                        filled: filledKeys,
                    },
                };

                if (filledKeys.length > 0) {
                    const res: { id: string } = await postData(url, data, token);
                    const id = res.id;
                    const newDocument = {
                        id: id,
                        metadata: {
                            docType: step,
                            docStatus: 'valid',
                            docReviewDate: '2025-06-20',
                        },
                        payloadState: {
                            filled: filledKeys as string[],
                        },
                    };

                    set({
                        loading: false,
                        userDocuments: state.userDocuments ? [...state.userDocuments, newDocument] : [newDocument],
                    });
                } else {
                    set({ loading: false });
                }
            } catch (error) {
                set({ error: (error as Error).message, loading: false });
            }
        },

        updateDataForStep: async <K extends keyof WizardFormData[keyof WizardFormData]>(step: K, id: string, token: string, wizard?: keyof WizardFormData) => {
            set({ loading: true });
            const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents/${id}`;

            const activeWizard = wizard ?? get().activeWizard;

            if (!activeWizard) return;

            try {
                const state = get();
                const payload = state.formData[activeWizard][step];
                const existingDocument = state.userDocuments?.find((doc) => doc.id === id);
                const filledKeys = (Object.keys(payload) as (keyof WizardFormData[keyof WizardFormData][typeof step])[]).filter((key) => payload[key] !== '');
                const data = {
                    metadata: {
                        docStatus: 'valid',
                        docReviewDate: '2025-06-20',
                        docType: step,
                    },
                    payload: payload,
                    payloadState: {
                        filled: filledKeys,
                    },
                };

                await updateData(url, data, token);
                const updatedDocument = {
                    id: id,
                    metadata: {
                        docType: step,
                        docStatus: 'valid',
                        docReviewDate: '2025-06-20',
                    },
                    payloadState: {
                        filled: filledKeys as string[],
                    },
                    isFileUploaded: existingDocument?.isFileUploaded,
                };
                set((state) => ({
                    ...state,
                    loading: false,
                    userDocuments: state.userDocuments?.map((doc) => (doc.id === id ? updatedDocument : doc)),
                }));
            } catch (error) {
                console.log('error', error);
                set({ error: (error as Error).message, loading: false });
            }
        },

        deleteDataForStep: async <K extends keyof WizardFormData[keyof WizardFormData]>(step: K, id: string, token: string, wizard?: keyof WizardFormData) => {
            set({ loading: true });
            const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents/${id}`;

            try {
                const activeWizard = wizard ?? get().activeWizard;
                const currentFormData = get().formData;
                if (!activeWizard) {
                    return;
                }

                const emptyStepData = Object.keys(currentFormData[activeWizard][step]).reduce<Record<string, string>>((acc, key) => {
                    acc[key] = '';
                    return acc;
                }, {});

                set((state) => ({
                    ...state,
                    userDocuments: state.userDocuments?.filter((document) => document.id !== id),
                    formData: {
                        ...state.formData,
                        [activeWizard]: {
                            ...state.formData[activeWizard],
                            [step]: emptyStepData,
                        },
                    },
                    loading: false,
                }));
                await deleteData(url, token);
            } catch (error) {
                console.log('error', error);
                set({ error: (error as Error).message, loading: false });
            }
        },

        uploadDocumentFile: async (url, file, docType) => {
            try {
                await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'file/pdf',
                    },
                    body: file,
                });
                set((state) => ({
                    ...state,
                    userDocuments: state.userDocuments?.map((doc) => (doc.metadata.docType === docType ? { ...doc, isFileUploaded: true } : doc)),
                }));
            } catch (error) {
                console.error('Error during upload:', error);
            }
        },

        deleteFile: async (id: string, token: string, docType): Promise<string | undefined> => {
            set({ loading: true });
            const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents/${id}/file`;
            try {
                await deleteData(url, token);
                set((state) => ({
                    ...state,
                    userDocuments: state.userDocuments?.map((doc) => (doc.metadata.docType === docType ? { ...doc, isFileUploaded: false } : doc)),
                }));
                return 'Success';
            } catch (error) {
                console.log('error', error);
                set({ error: (error as Error).message, loading: false });
                return undefined;
            }
        },

        getFileUploadUrl: async (id: string, token: string) => {
            set({ loading: false });
            const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents/${id}/file-upload-url`;

            try {
                const res: UploadUrlResponse = await getData(url, token);

                if (res?.url) {
                    return res?.url;
                }

                return undefined;
            } catch (error) {
                set({ error: (error as Error).message, loading: false });
                return undefined;
            }
        },
        setActiveWizard: (wizard) => {
            set({ activeWizard: wizard });
        },
    })),
);
