import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import theme from 'src/theme';
import * as Styled from './styles';

interface IInput {
    label?: string;
    placeholder?: string;
    value: string;
    onChange: (e: { target: { name: string; value: string } }) => void;
    name: string;
    type?: string;
    multiline?: boolean;
    rows?: number;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}

const InputComponent: React.FC<IInput> = ({
    label,
    placeholder,
    value,
    onChange,
    name,
    type = 'text',
    multiline = false,
    rows = 0,
    required,
    error,
    helperText,
    disabled,
}) => {
    return (
        <FormControl variant="standard" sx={{ width: '100%', margin: '10px 0' }}>
            <InputLabel shrink htmlFor="input" sx={{ color: theme.palette.text.primary }}>
                {label}
            </InputLabel>
            <Styled.Input
                required={required}
                multiline={multiline}
                rows={rows}
                placeholder={placeholder}
                id="input"
                value={value}
                onChange={onChange}
                name={name}
                type={type}
                error={error}
                disabled={disabled}
            />
            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default InputComponent;
