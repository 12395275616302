import { InputBase, styled } from '@mui/material';

export const Input = styled(InputBase)<{ error?: boolean }>(({ theme, error }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 6,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid',
        borderColor: error ? theme.palette.error.main : theme.palette.primary.light,
        fontSize: theme.spacing(2),
        color: theme.palette.grey[200],
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: '0.00938em',
        width: '100%',
        padding: '12px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        '&:focus': {
            borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
            boxShadow: error ? `0 0 0 2px ${theme.palette.error.light}` : `0 0 0 2px ${theme.palette.primary.light}`,
        },
        '&:hover': {
            borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
        },
        ...theme.applyStyles('dark', {
            backgroundColor: '#1A2027',
            borderColor: error ? theme.palette.error.dark : '#2D3843',
        }),
    },
}));
