import { IModal } from '@interfaces/IModal';
import Modal from '../modal/Modal';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '@components/button/Button';
import Logo from '@assets/images/logo.svg';

const SubscribedModal = ({ openModal, onClose }: IModal) => {
    const { t } = useTranslation();

    return (
        <Modal hasBottomCloseButton={false} openModal={openModal} onClose={onClose}>
            <Stack justifyContent={'center'} alignItems={'center'} gap={2} mt={6}>
                <img src={Logo} alt="logo" height={100} />
                <Typography variant="h4" fontWeight={700} mt={4}>
                    {t('subscribed')}
                </Typography>
                <Typography textAlign="center" maxWidth={'80%'} variant="body2" mt={2} mb={4}>
                    {t('subscribed_text')}
                </Typography>
                <Stack width={140} mb={8}>
                    <ButtonComponent color="info" text={t('common.close_button_label')} onClick={() => onClose(false)} />
                </Stack>
            </Stack>
        </Modal>
    );
};

export default SubscribedModal;
